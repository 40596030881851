// import ChartEchartsTitleModule from './chart-echarts-title'
import EchartsTitleReactModule from './chart-echarts-title-react';
import { EchartsCustomFactory } from './chart-echarts-wrapper.directive';
import { EchartsBarChartModelFactory } from './chart-echarts-bar-chart-model.coffee';
import { EChartsLineChartModelFactory } from './chart-echarts-line-chart-model.coffee';
import { EchartsMapChartModelInstance } from './chart-echarts-map-chart-model';
import { EchartsPieChartModelFactory } from './chart-echarts-pie-chart-model.coffee';
import { EchartsWithQueryViewModelFactory } from './chart-echarts-with-query-view-model';

const module = angular.module('42.directives.charts', [EchartsTitleReactModule.name]);
module.directive('echartsCustom', EchartsCustomFactory());
module.factory('EchartsWithQueryViewModel', EchartsWithQueryViewModelFactory());
module.factory('EchartsBarChartModel', EchartsBarChartModelFactory());
module.factory('EchartsPieChartModel', EchartsPieChartModelFactory());
module.factory('EchartsLineChartModel', EChartsLineChartModelFactory());
module.factory('EchartsMapChartModel', EchartsMapChartModelInstance());

export default module;
