import _ from 'lodash'
import moment from 'moment-timezone';
import { titleize } from 'inflected'
import { PeriodComparisonMode } from './datepicker-comparison-mode.model'


calculateToday = (date) ->
    trueToday = date.getClass().CreateFromDate(moment())
    return trueToday if date.diff(trueToday) >= 0
    return date

###* @returns {import('./datepicker-actions').IDatepickerActionDefinition} RollingCompleteNWeeksAction ###
RollingCompleteNWeeksAction = (weeks) ->
    {id:"rolling-complete-#{weeks}-weeks", label:"Last Complete <em>#{weeks} Weeks</em>", hide:true, getSelection: ({end}) ->
        isCurrentWeekComplete = end.eq end.clone().endOf('week').subtract(1, 'day')
        end = end.clone().startOf('week').subtract(1, 'day') if not isCurrentWeekComplete
        start = end.clone().subtract(weeks, 'weeks').add(1, 'day')
        return {start, end}
    }

###* @returns {import('./datepicker-actions').IDatepickerActionDefinition} XToYesterdayAction ###
XToYesterdayAction = (id, label, period) ->
    {id:id, label:label, hide:true, getSelection: ({end}) ->
        end = end.subtract(1, 'day')
        return {start:end.startOf(period), end}
    }

###* @returns {import('./datepicker-actions').IDatepickerActionDefinition} CompleteAction ###
CompleteAction = ({id, label, period, complete, offset}) ->
    id: id,
    label: label,
    getSelection: ({end}) ->
        offset = 1 if Number.isNaN(parseInt(offset))
        offset = Math.max(1, offset ? 1)
        # We shift to the last complete 'size', if we're not there already
        isCompletePeriod = end.eq(end.endOf(complete).subtract(1, 'day'))
        end = end.startOf(complete).subtract(1, 'day') if not isCompletePeriod
        end = end.subtract(offset-1, period)
        start = end.startOf(period)
        return {start, end}

###* @returns {import('./datepicker-actions').IDatepickerActionDefinition} LastCompleteAction ###
LastCompleteAction = (period) ->
    id: "last-complete-#{period.toLowerCase()}"
    label: "Last Complete <em>#{titleize(period)}</em>"
    getSelection: ({end}) ->
        end = end.startOf(period).subtract(1, period) if not (end.eq end.endOf(period).subtract(1, 'day'))
        return {start:end.startOf(period), end:end.endOf(period).subtract(1, 'day')}

###* @returns {import('./datepicker-actions').IDatepickerActionDefinition} XFromNowAction ###
XFromNowAction = (period) ->
    id: "#{period.toLowerCase()}-from-now"
    label: "Last Trailing <em>#{titleize(period)}</em>"
    getSelection: ({end}) ->
        return {start:end.subtract(1, period).add(1, 'day'), end}

###* @returns {import('./datepicker-actions').IDatepickerActionDefinition} XToDateAction ###
XToDateAction = (period) ->
    id: "#{period.toLowerCase()[0]}td"
    label: "<em>#{titleize(period)[0]}TD</em> – #{titleize(period)} to Date"
    hide: do ->
        return true if period is "day"
        return false
    getSelection: ({start, end}) ->
        start = end.startOf(period)
        return {start, end}

###* @returns {import('./datepicker-actions').IDatepickerActionDefinition} NXForecastFromNowAction ###
NXForecastFromNowAction = (units, unitType) ->
    unitTypeLabel = do ->
        singular = do ->
            return unitType.slice(0, unitType.length - 1) if unitType[unitType.length - 1] is 's'
            return unitType
        return titleize(singular)
    id: "forecast-from-now-#{units}-#{unitType.toLowerCase()}"
    label: "<em>#{units}</em> #{unitTypeLabel} Forecast"
    getSelection: ({end}) ->
        return {start:end, end:end.add(units, unitType)}

###* @returns {import('./datepicker-actions').IDatepickerActionDefinition} ForecastFromNowToEndOfPeriodAction ###
ForecastFromNowToEndOfPeriodAction = (period) ->
    period = period.toLowerCase()
    periodLabel = titleize(period)
    id: "forecast-from-now-to-end-of-#{period}"
    label: "End Of <em>#{periodLabel}</em> Forecast (Remaining)"
    getSelection: ({end}) ->
        return {start:end, end:end.endOf(period).subtract(1, 'day')}

###* @returns {import('./datepicker-actions').IDatepickerActionDefinition} ForecastCompletePeriodAction ###
ForecastCompletePeriodAction = (period) ->
    period = period.toLowerCase()
    periodLabel = titleize(period)
    id: "forecast-complete-#{period}"
    label: "End Of <em>#{periodLabel}</em> Forecast"
    getSelection: ({end}) ->
        return {start:end.startOf(period), end:end.endOf(period).subtract(1, 'day')}

###*
@type {import('./datepicker-actions').IDatepickerActionDefinition[]} actions
###
DATEPICKER_ACTIONS = [
    # Today for current-day updates
    XToDateAction('day')

    # NOTE: keeping both for backwards compatible references
    {id:'yesterday', label:'<em>Yesterday</em>', getSelection: ({end}) ->
        return {start:end, end}
    }

    # Yesterday for current-day updates
    {id:'last-complete-day', label:'<em>Yesterday</em>', hide: true, getSelection: ({end}) ->
        end = end.subtract(1, 'day')
        return {start:end, end}
    }

    XToDateAction('week')
    XToDateAction('month')
    XToDateAction('quarter')
    XToDateAction('season')
    XToDateAction('year')


    {...LastCompleteAction('week'), section: true}
    {
        id:'last-complete-week-over-week',
        label:'Complete <em>Week</em> over <em>Week</em>',
        getSelection: ({end}) ->
            end = end.startOf('week').subtract(1, 'week') if not (end.eq end.endOf('week').subtract(1, 'day'))
            return {start:end.startOf('week'), end:end.endOf('week').subtract(1, 'day')}
        getComparison: PeriodComparisonMode()
    }
        LastCompleteAction('month')
        LastCompleteAction('quarter')
        LastCompleteAction('season')
        LastCompleteAction('year')

    {...CompleteAction({id:'last-complete-mtd', label:'<em>MTD</em> – Complete Weeks', period:'month', complete:'week'}), section: true}
        CompleteAction({id:'last-complete-qtd', label:'<em>QTD</em> – Complete Weeks', period:'quarter', complete:'week'})
        CompleteAction({id:'last-complete-std', label:'<em>STD</em> – Complete Weeks', period:'season', complete:'week'})
        CompleteAction({id:'last-complete-ytd', label:'<em>YTD</em> – Complete Weeks', period:'year', complete:'week'})

    {...CompleteAction({id:'complete-months-qtd', label:'<em>QTD</em> – Complete Months', period:'quarter', complete:'month'}), section: true}
        CompleteAction({id:'complete-months-std', label:'<em>STD</em> – Complete Months', period:'season', complete:'month'})
        CompleteAction({id:'complete-months-ytd', label:'<em>YTD</em> – Complete Months', period:'year', complete:'month'})

    {...RollingCompleteNWeeksAction(4), section:true}
        RollingCompleteNWeeksAction(6)
        RollingCompleteNWeeksAction(8)
        RollingCompleteNWeeksAction(13)
        RollingCompleteNWeeksAction(26)
        RollingCompleteNWeeksAction(52)

    {...XToYesterdayAction('wtd-complete-day',  '<em>WTY</em> – Week To Yesterday',    'week'), section:true}
        XToYesterdayAction('mtd-complete-day',  '<em>MTY</em> – Month To Yesterday',   'month')
        XToYesterdayAction('qtd-complete-day',  '<em>QTY</em> – Quarter To Yesterday', 'quarter')
        XToYesterdayAction('std-complete-day',  '<em>STY</em> – Season To Yesterday',  'season')
        XToYesterdayAction('ytd-complete-day',  '<em>YTY</em> – Year To Yesterday',    'year')

    {...XFromNowAction('week'), section:true}
        XFromNowAction('month')
        XFromNowAction('quarter')
        XFromNowAction('season')
        XFromNowAction('year')

    {...CompleteAction({id:'last-complete-2-week', label:'2 Weeks Ago', period:'week', complete:'week', offset:2}), hide:true, section:true}
    {...CompleteAction({id:'last-complete-3-week', label:'3 Weeks Ago', period:'week', complete:'week', offset:3}), hide:true}

    {...NXForecastFromNowAction(30, 'days'), hide: true}
    {...NXForecastFromNowAction(60, 'days'), hide: true}
    {...NXForecastFromNowAction(90, 'days'), hide: true}

    # NOTE (kevin): the intent is effectively "all time"
    # https://www.youtube.com/watch?v=aQ4Sb_rnCqw
    {...NXForecastFromNowAction(100, 'years'), hide: true}

    {...ForecastFromNowToEndOfPeriodAction('week'), hide: true}
    {...ForecastFromNowToEndOfPeriodAction('month'), hide: true}
    {...ForecastFromNowToEndOfPeriodAction('quarter'), hide: true}
    {...ForecastFromNowToEndOfPeriodAction('year'), hide: true}

    {...ForecastCompletePeriodAction('week'), hide: true}
    {...ForecastCompletePeriodAction('month'), hide: true}
    {...ForecastCompletePeriodAction('quarter'), hide: true}
    {...ForecastCompletePeriodAction('year'), hide: true}

    {id:'same-day-ly', label:'<em>Same Day</em> Last Year', section:true, hide:false, getSelection: ({end}) ->
        lastYear = end.subtract(1, 'year')
        return {start:lastYear.startOf('day'), end:lastYear}
    }

    {
        id:'wtd-over-wtd',
        label:'<em>WTD</em> over <em>WTD</em>',
        hide:true,
        getSelection: ({end}) ->
            return {start:end.startOf('week'), end}
        getComparison: PeriodComparisonMode()
    }

    {id:'all', label:'All Time', section:true, getSelection: (bounds) -> {...bounds}}
]


###*
@param {null | import('./datepicker-actions').IDatepickerActionOverride[]} [overrides]
@returns {import('./datepicker-actions').IDatepickerActionDefinitionResolved[]}
###
resolveDatepickerActionDescriptors = (overrides) ->
    overridesById = do ->
        return null if not Array.isArray(overrides)
        overrides = overrides.map (x, index) -> ({hide: false, ...x, index})
        return _.keyBy(overrides, (x) -> x.id)

    actions = do ->
        return DATEPICKER_ACTIONS if not overridesById
        return DATEPICKER_ACTIONS.filter (action) -> Boolean(overridesById?[action.id])

    resolved = actions.map (action, index) ->
        return {index, ...action, ...(overridesById?[action.id])}

    return _.sortBy(resolved, (x) -> x.index)


###* @type {import('./datepicker-actions').DatepickerActionsFactory} ###
export DatepickerActionsFactory = (overrides) ->
    actions = resolveDatepickerActionDescriptors(overrides)
    actions = _.cloneDeep(actions)
    models  = actions.map(DatepickerActionViewModelFactory)
    return (datepicker) -> models.map (DatepickerActionViewModel) -> new DatepickerActionViewModel(datepicker)


###* @type {import('./datepicker-actions').DatepickerActionViewModelFactory} ###
export DatepickerActionViewModelFactory = (action) ->

    return class DatepickerActionViewModel

        ###* @param {import('./datepicker-actions').IDatepickerActionParams} datepicker ###
        constructor: (datepicker) ->
            @datepicker = datepicker
            @action   = _.cloneDeep(action)
            @id       = @action.id
            @label    = @action.label ? @action.id
            @section  = @action.section ? false
            @hide     = @action.hide ? false
            @index    = @action.index
            @bounds   = {start: @datepicker.bounds.start, end: calculateToday(@datepicker.bounds.end)}
            return

        getRange: ->
            selection = @getSelection()
            comparison = @getComparison(selection)
            return {selection, comparison}

        getSelection: ->
            return @action.getSelection(@bounds)

        ###* @param {null | undefined | import('./datepicker-timerange.model').IDateSelectModelTimeRange} [selection] ###
        getComparison: (selection = null) ->
            selection ?= @getSelection()
            return @action.getComparison(selection, @bounds) if @action.getComparison
            return @datepicker.comparisonMode?.getComparisonTimerange(selection, @bounds) or null

        isActive: (range = null) =>
            return @action.isActive() if @action.isActive
            {start, end} = (range?.selection or @getSelection())
            (@datepicker.selection.selected.start or start).eq(start) and \
            (@datepicker.selection.selected.end or end).eq(end)

        isDisabled: (range = null) =>
            return @action.isDisabled() if @action.isDisabled
            range  = (range?.selection or @getSelection())
            result = false
            result or= range.start.lt(@bounds.start) if @bounds?.start
            result or= range.end.gt(@bounds.end)     if @bounds?.end
            return result
