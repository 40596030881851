import _ from 'lodash';
import { TextareaAutoSize } from '../../lib/dom/textarea-autosize';
import type { IQueryFilters } from '../../lib/types';
import type { AngularInjected } from '../../lib/angular';
import type { IPropertyDefinition } from '../../lib/config-hierarchy';
import { createRegex } from '../../lib/parsers/regexp';
import type {
    ISegmentProperty,
    ISegmentPropertyFilter,
    ISegmentsPropertiesService,
} from './smart-group-filter.directive';

const MAX_NUMBER_OF_ITEMS_FOR_SELECT_ALL = 200;

export interface IPropertyViewProps {
    property: ISegmentProperty;
    queryFilters: IQueryFilters;
    onPropertyFilterClick: (propertyView: IPropertyView) => void;
    onClose: (propertyView: IPropertyView) => void;
    onBack?: undefined | ((propertyView: IPropertyView) => void);
}

export interface IExportedPropertyView {
    id: string;
    table: string;
    column: string;
    excludeMode: boolean;
    values: ISegmentPropertyFilter[];
}

export interface IPropertyViewValues {
    excludeMode: boolean;
    values: ISegmentPropertyFilter[] | null | undefined;
    selectedFilters: Record<string, boolean>;
}

export interface IPropertyViewValuesProps {
    excludeMode: boolean;
    selectedFilters?: Record<string, boolean> | undefined;
    queryFilters: IQueryFilters;
    property: ISegmentProperty;
    values?: ISegmentPropertyFilter[] | null | undefined;
}

export type PropertyView = AngularInjected<typeof PropertyViewFactory>;
export type IPropertyView = InstanceType<PropertyView>;

export const PropertyViewFactory = () => [
    'SegmentsPropertiesService',
    function PropertyViewFn(SegmentsPropertiesService: ISegmentsPropertiesService) {
        class PropertyViewValues implements IPropertyViewValues {
            excludeMode: boolean;
            values: ISegmentPropertyFilter[] | null | undefined;
            selectedFilters: Record<string, boolean>;
            error = false;

            constructor({ selectedFilters, queryFilters, property, excludeMode, values }: IPropertyViewValuesProps) {
                this.excludeMode = excludeMode;
                this.selectedFilters = selectedFilters ?? {};
                this.values = values ?? null;
                void SegmentsPropertiesService.fetchPropertyValues(queryFilters, property)
                    .then(newValues => {
                        const values = newValues ?? [];

                        if (selectedFilters === undefined) {
                            const queryFiltersSelectedPropertyFilters = property?.values
                                ? _.keyBy(property.values, 'id')
                                : {};

                            this.selectedFilters = (newValues ?? []).reduce((acc: Record<string, boolean>, value) => {
                                if (queryFiltersSelectedPropertyFilters[value.id]?.selected) {
                                    acc[value.id] = true;
                                    if (queryFiltersSelectedPropertyFilters[value.id]?.excluded) {
                                        this.excludeMode = true;
                                    }
                                }
                                return acc;
                            }, {});
                        } else {
                            if (!_.isEmpty(this.selectedFilters)) {
                                const newSelectedFilters = values.reduce((acc: Record<string, boolean>, value) => {
                                    if (this.selectedFilters[value.id]) acc[value.id] = true;
                                    return acc;
                                }, {});

                                this.selectedFilters = newSelectedFilters;
                            }
                        }

                        values.sort((a, b) => {
                            if (this.selectedFilters[a.id] === this.selectedFilters[b.id]) return 0;
                            if (this.selectedFilters[b.id]) return 1;
                            return -1;
                        });

                        this.values = values;
                    })
                    .catch(err => {
                        this.error = true;
                        throw err;
                    });
            }
        }

        class PropertyView {
            public property: IPropertyDefinition;
            propertyValues: PropertyViewValues;
            public props: IPropertyViewProps;
            private oldSelectedFilters: Record<string, boolean> = {};
            back: undefined | ((propertyView: PropertyView) => void);

            constructor(props: IPropertyViewProps) {
                this.props = props;
                this.back = props.onBack ? () => props.onBack?.(this) : undefined;
                this.property = props.property;
                this.propertyValues = new PropertyViewValues({
                    queryFilters: this.props.queryFilters,
                    property: this.property,
                    excludeMode: false,
                });
            }

            toggleMultiplePropertyFilter(values: ISegmentPropertyFilter[]) {
                this.propertyValues.selectedFilters = (() => {
                    const areAllValuesSelected = values.every(value => this.propertyValues.selectedFilters[value.id]);

                    if (areAllValuesSelected) {
                        const idsToUnselect = values.reduce((acc: string[], value) => {
                            acc.push(value.id);
                            return acc;
                        }, []);

                        return _.omit(this.propertyValues.selectedFilters, idsToUnselect);
                    }

                    const selectedValues = values.reduce((acc: Record<string, boolean>, value) => {
                        acc[value.id] = true;
                        return acc;
                    }, {});

                    return { ...this.propertyValues.selectedFilters, ...selectedValues };
                })();

                this.oldSelectedFilters = {};

                // UPDATE FILTERS;
                this.props.onPropertyFilterClick(this);
            }

            unSelectPropertyFilter(value: ISegmentPropertyFilter) {
                this.propertyValues.selectedFilters[value.id] = false;
                this.togglePropertyFilter(value);
            }

            unSelectAllPropertyFilters() {
                this.propertyValues.selectedFilters = {};
                this.props.onPropertyFilterClick(this);
            }

            togglePropertyFilter(value: ISegmentPropertyFilter) {
                // Break the reference to trigger Watch
                this.propertyValues.selectedFilters = !this.propertyValues.selectedFilters[value.id]
                    ? _.omit(this.propertyValues.selectedFilters, [value.id])
                    : { ...this.propertyValues.selectedFilters };

                this.oldSelectedFilters = {};

                // UPDATE FILTERS;
                this.props.onPropertyFilterClick(this);
            }

            toggleExcludeMode() {
                this.propertyValues.excludeMode = !this.propertyValues.excludeMode;
                const oldSelectedFilters = this.propertyValues.selectedFilters;

                // Revert when toggle is Clicked
                this.propertyValues.selectedFilters = this.oldSelectedFilters;
                this.oldSelectedFilters = oldSelectedFilters;

                // UPDATE FILTERS
                this.props.onPropertyFilterClick(this);
            }

            updatePropertyValues(queryFilters: IQueryFilters) {
                this.propertyValues = new PropertyViewValues({
                    queryFilters,
                    property: this.property,
                    selectedFilters: this.propertyValues.selectedFilters,
                    excludeMode: this.propertyValues.excludeMode,
                    values: this.propertyValues.values,
                });
            }

            getSelectedPropertyFilters(): IExportedPropertyView | undefined {
                const { id, table, column } = this.property;
                if (!table || !column) return;
                const excludeMode = this.propertyValues.excludeMode;
                const available = this.propertyValues.values ?? [];
                const values = available.filter(value => this.propertyValues.selectedFilters[value.id]);
                return { id, table, column, values, excludeMode };
            }

            sort() {
                const selected = this.propertyValues.selectedFilters;
                this.propertyValues.values?.sort((a, b) => {
                    if (selected[a.id] === selected[b.id]) return 0;
                    if (selected[b.id]) return 1;
                    return -1;
                });
            }

            close() {
                this.props.onClose(this);
            }
        }

        return PropertyView;
    },
];

const parsePropertyFilterSearch = (search: string): Set<string> => {
    return new Set(
        search
            .split('\n')
            .map(value => value.trim().toLowerCase())
            .filter(x => x.length > 0),
    );
};

const findPropertyFilters = (values: ISegmentPropertyFilter[], search: string): ISegmentPropertyFilter[] => {
    const terms = parsePropertyFilterSearch(search);
    if (terms.size <= 1) {
        // One line means we do a case insensitive fuzzy search
        search = search.trim().toLowerCase();
        return values.filter(value => value.search.includes(search));
    } else {
        // Multiple lines means we do a case insensitive exact match
        return values.filter(value => terms.has(value.search));
    }
};

interface ICheckboxState {
    label: string;
    disabled: boolean;
    selected: boolean;
    all?: boolean;
    messages?: string[];
}

const CHECKBOX_STATES = {
    unselectAll: {
        label: 'Unselect All',
        disabled: false,
        selected: true,
    },
    selectedAll: {
        label: 'Unselect All',
        disabled: false,
        selected: true,
        all: true,
    },
    emptySelectAll: {
        label: 'Select All',
        disabled: false,
        selected: false,
    },
    selectAll: {
        label: 'Select All',
        disabled: false,
        selected: true,
    },
    disabled: {
        label: 'Select / Unselect All',
        disabled: true,
        selected: false,
    },
};

interface SelectionCheckboxDirectiveScope extends angular.IScope {
    view: ICheckboxState;
    model?: ICheckboxState;
    toggleSelectAllCheckbox: (selected: boolean) => void;
    onToggleCheckbox: (selected: boolean) => void;
}

export const SelectionCheckboxDirective = () => [
    function SelectionCheckbox(): angular.IDirective<SelectionCheckboxDirectiveScope> {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                model: '=',
                onToggleCheckbox: '=',
            },
            template: `
                <div class="select-all-container"
                    ng-class="{
                        'disabled': view.disabled,
                        'selected-all': !view.disabled && view.selected && view.all,
                        'unselect-all': !view.disabled && !view.all,
                        'selected': view.selected
                    }">
                    <div class="select-all-description">
                        <label>
                            <div class="checkbox-container">
                                <input type="checkbox" ng-model="view.selected" ng-change="onToggleCheckbox()">
                            </div>
                            <div class="select-all-label">{{ view.label }}</div>
                        </label>
                    </div>
                    <tooltip ng-if="view.messages" messages="view.messages"></tooltip>
                </div>
            `,
            link: function SelectionCheckboxLink(scope) {
                scope.view = _.cloneDeep(scope.model ?? CHECKBOX_STATES.disabled);
                scope.$watch('model', () => {
                    scope.view = _.cloneDeep(scope.model ?? CHECKBOX_STATES.disabled);
                });
            },
        };
    },
];

interface ISmartGroupItemPropertyFilterScope extends angular.IScope {
    model: IPropertyView;
    filteredValues: ISegmentPropertyFilter[];
    limit: number;
    search: { value: string };
    selectedValues: { ids: Record<string, boolean | undefined>; isIncluded: boolean };
    selectAllCheckboxState: boolean;
    selectionCheckbox: ICheckboxState;
    clearSearch: () => void;
    load: () => void;
    remove: ($event: MouseEvent) => void;
    togglePropertyFilterCheckbox: (value: ISegmentPropertyFilter) => void;
    toggleExcludeMode: () => void;
    toggleSelectAllCheckbox: () => void;
    back: ($event: MouseEvent) => void;
}

export const SegmentPropertyFilterDirective = () => [
    function SegmentPropertyFilter(): angular.IDirective<ISmartGroupItemPropertyFilterScope> {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                model: '=',
            },
            template: `
                <article class="item-property-filter">
                    <div class="item-property-filter-header">
                        <div class="description" ng-class="{'with-back-button': model.back  }">
                            <div class="remove-property chevron" ng-if="model.back" ng-click="back($event)">
                                <i class="icon-left-open-mini"></i>
                            </div>
                            <div class="property-label">
                                {{ model.property.label }}
                            </div>
                            <div class="remove-property" ng-if="!model.back" ng-click="remove($event)">
                                <div class="remove-property-icon">×</div>
                            </div>
                        </div>
                    </div>

                    <main class="item-property-filter-list" ng-class="{'exclude': model.propertyValues.excludeMode, 'error': model.propertyValues.error }">
                        <div class="loadable" ng-class="{loading: !model.propertyValues.values && !model.propertyValues.error}"></div>
                        <div class="message-container">
                            <div class="message">
                                <i class="message-icon icon-attention"></i>
                                <span class="message-summary">Data could not be loaded</span>
                            </div>
                        </div>
                        <div class="toggle-mode">
                            <div class="toggle-title include-mode">Include</div>
                            <div class="switch-box">
                                <input ng-click="toggleExcludeMode()" ng-checked="model.propertyValues.excludeMode" type="checkbox" class="switch">
                            </div>
                            <div class="toggle-title exclude-mode">Exclude</div>
                        </div>
                        <div class="filter-input-search">
                            <textarea class="resize-ta" type="text" placeholder="search" ng-model="search.value" ng-trim="false"></textarea>
                            <i class="icon-search"></i>
                            <div class="filter-input-search-buttons">
                                <button-close on-close="clearSearch" ng-if="search.value !== ''"></button-close>
                            </div>
                        </div>
                        <selection-checkbox
                            model="selectionCheckbox"
                            on-toggle-checkbox="toggleSelectAllCheckbox"
                        ></selection-checkbox>
                        <div class="item-property-values-container" infinite-scroll="load()">
                            <ul class="item-property-values">
                                <li
                                ng-class="{ unavailable: value.count === 0, selected: model.propertyValues.selectedFilters[value.id] }"
                                class="item-property-value" ng-repeat="value in filteredValues | limitTo:limit track by value.id">
                                    <label>
                                        <div class="checkbox-container">
                                            <input type="checkbox" ng-model="model.propertyValues.selectedFilters[value.id]" ng-change="togglePropertyFilterCheckbox(value)">
                                        </div>
                                        <span class="item-property-value-id" ng-bind-html="value.label"></span>
                                        <span class="item-property-value-count" ng-bind-html="value.count"></span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </main>
                </article>
            `,
            link: ($scope, $element) => {
                const $main = $($element).find('main');

                const textAreaWrapper = new TextareaAutoSize(
                    (() => {
                        const el = $element[0]?.querySelector('.resize-ta');
                        if (!el) throw new Error('could not find: resize-ta');
                        return el;
                    })(),
                );

                const resetScrollLimit = () => ($scope.limit = 30);
                $scope.search = { value: '' };
                $scope.selectionCheckbox = CHECKBOX_STATES.disabled;

                $scope.toggleSelectAllCheckbox = () => {
                    if ($scope.selectionCheckbox.disabled) return;
                    if (
                        $scope.filteredValues.length <= MAX_NUMBER_OF_ITEMS_FOR_SELECT_ALL &&
                        $scope.search.value !== ''
                    ) {
                        $scope.model.toggleMultiplePropertyFilter($scope.filteredValues);
                    } else {
                        $scope.model.unSelectAllPropertyFilters();
                    }
                };

                $scope.filteredValues = [];
                $scope.limit = 20;
                $scope.load = () => ($scope.limit += 20);

                $scope.togglePropertyFilterCheckbox = (value: ISegmentPropertyFilter) => {
                    $scope.model.togglePropertyFilter(value);
                };

                $scope.toggleExcludeMode = () => {
                    $scope.model.toggleExcludeMode();
                };

                $scope.back = ($event: MouseEvent) => {
                    $event.preventDefault();
                    $event.stopImmediatePropagation();
                    $scope.model.back?.($scope.model);
                };

                $scope.remove = ($event: MouseEvent) => {
                    $event.stopImmediatePropagation();
                    $scope.model.close();
                };

                $scope.clearSearch = () => {
                    $scope.search.value = '';
                    const textarea = $element[0]?.querySelector('.resize-ta');
                    if (!textarea) return;
                    if ('focus' in textarea && typeof textarea.focus === 'function') {
                        textarea.focus();
                    }
                };

                const updateSearchHighlight = () => {
                    const labelHighlightRegexp = (() => {
                        const terms = parsePropertyFilterSearch($scope.search.value);
                        if (terms.size !== 1) return null;
                        const search = Array.from(terms.values()).pop();
                        return search ? createRegex(search.replace(/'/g, '’'), 'gi') : null;
                    })();
                    // Only change values label which are present in view
                    const values = $scope.filteredValues;
                    for (let i = 0; i < $scope.limit && i < $scope.filteredValues.length; i++) {
                        const value = values[i];
                        if (!value) continue;
                        value.label = value.ogLabel ?? value.label;
                        if (!labelHighlightRegexp) continue;
                        value.label = value.label.replace(labelHighlightRegexp, '<span class="ui-match">$&</span>');
                    }
                };

                const createDisabledSelectionCheckboxState = (): ICheckboxState => {
                    const selectionCheckbox: ICheckboxState = _.cloneDeep(CHECKBOX_STATES.disabled);
                    selectionCheckbox.messages = [];
                    if ($scope.search.value === '') {
                        selectionCheckbox.messages.push('No active search');
                    } else if ($scope.filteredValues.length === 0) {
                        selectionCheckbox.messages.push('No results to select');
                    } else if ($scope.filteredValues.length > MAX_NUMBER_OF_ITEMS_FOR_SELECT_ALL) {
                        selectionCheckbox.messages.push(`Too many results (Max ${MAX_NUMBER_OF_ITEMS_FOR_SELECT_ALL})`);
                    }
                    return selectionCheckbox;
                };

                const updateSelectAllCheckbox = () => {
                    if ($scope.filteredValues.length === 0) {
                        $scope.selectionCheckbox = createDisabledSelectionCheckboxState();
                        return;
                    }

                    if ($scope.filteredValues.length > MAX_NUMBER_OF_ITEMS_FOR_SELECT_ALL) {
                        const selectedValue = $scope.filteredValues.find(
                            value => $scope.model.propertyValues.selectedFilters[value.id],
                        );

                        $scope.selectionCheckbox = selectedValue
                            ? CHECKBOX_STATES.unselectAll
                            : createDisabledSelectionCheckboxState();

                        return;
                    }

                    if ($scope.filteredValues.length <= MAX_NUMBER_OF_ITEMS_FOR_SELECT_ALL) {
                        if ($scope.search.value === '') {
                            const isAnyValueSelected = $scope.filteredValues.find(
                                value => $scope.model.propertyValues.selectedFilters[value.id],
                            );

                            $scope.selectionCheckbox = isAnyValueSelected
                                ? CHECKBOX_STATES.unselectAll
                                : createDisabledSelectionCheckboxState();
                        } else {
                            if ($scope.filteredValues.length === 0) {
                                $scope.selectionCheckbox = createDisabledSelectionCheckboxState();
                                return;
                            }

                            const areAllValuesSelected = $scope.filteredValues.every(
                                value => $scope.model.propertyValues.selectedFilters[value.id],
                            );

                            if (areAllValuesSelected) {
                                $scope.selectionCheckbox = CHECKBOX_STATES.selectedAll;
                                return;
                            }

                            const isAnyValueSelected = $scope.filteredValues.find(
                                value => $scope.model.propertyValues.selectedFilters[value.id],
                            );

                            $scope.selectionCheckbox = isAnyValueSelected
                                ? CHECKBOX_STATES.selectAll
                                : CHECKBOX_STATES.emptySelectAll;
                        }
                    }
                };

                const updatePropertyFilterSearchList = (values?: ISegmentPropertyFilter[]) => {
                    textAreaWrapper.update();
                    const valuesToFilter = values ?? $scope.model.propertyValues.values;
                    $scope.filteredValues = valuesToFilter
                        ? findPropertyFilters(valuesToFilter, $scope.search.value)
                        : [];
                    updateSearchHighlight();
                    updateSelectAllCheckbox();
                };

                $scope.$watch('limit', () => updateSearchHighlight());
                $scope.$watch('search.value', () => updatePropertyFilterSearchList());
                $scope.$watch('model.propertyValues.selectedFilters', () => updateSelectAllCheckbox());
                $scope.$watch('model.propertyValues.values', (values: ISegmentPropertyFilter[] | undefined) => {
                    updatePropertyFilterSearchList(values);
                    $main.scrollTop(0);
                    resetScrollLimit();
                });

                $scope.$on('$destroy', () => {
                    textAreaWrapper.destroy();
                });
            },
        };
    },
];
