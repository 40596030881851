import { isRequestAbortedError } from '../api';
import * as EVENTS from './events';
export { EVENTS };

export function track(
    event: string,
    properties?: Record<string, unknown> | undefined,
    options?: SegmentAnalytics.SegmentOpts | undefined,
    callback?: () => void,
) {
    try {
        if (!event) throw new Error("Missing required 'event' argument.");
        window.analytics.track(event, properties, options, callback);
    } catch (error) {
        console.error(error);
    }
}

export function logError(error: unknown): void {
    // NOTE (kevin) handling unexpected inputs outside of the timeout in order to preserve the
    // stack when creating new error instances
    const normalizeError = (() => {
        if (error instanceof Error) return error;
        if (typeof error === 'string') return new Error(error);
        // NOTE (kevin): don't love, but intent is to provide as much information as possible;
        // if JSON.stringify fails, then you need to just trace the stack and fix the input.
        try {
            return new Error(`Unexpected input: ${JSON.stringify(error)}`);
        } catch {
            return new Error(`Unexpected input: unknown`);
        }
    })();

    // We don't need to throw these to sentry...?
    if (isRequestAbortedError(normalizeError) || isRequestAbortedError(normalizeError.cause)) {
        console.error('Request aborted:', normalizeError);
        return;
    }

    setTimeout(() => {
        throw normalizeError;
    }, 0);
}
