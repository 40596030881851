import SupertableDirectiveModule from './supertable.directive.coffee';
import ChartEchartsModule from './charts/charts-echarts.module';
import SortablePropertyModule from './sortable-property.directive';

import { onSrcDirectiveInstance } from './on-src-error.directive';
import { OutsideElementClickInstance } from './outside-element-click.directive';
import { PromiseTrackerInstance } from './promise-tracker.directive';
import ToggleSidebarDirectiveModule from './toggle-sidebar.directive';
import InputDirectivesModule from './input.directive';
import { OverlayDirectiveFactory } from './overlay.directive';

angular
    .module('42.directives', [
        SupertableDirectiveModule.name,
        ChartEchartsModule.name,
        SortablePropertyModule.name,
        InputDirectivesModule.name,
        ToggleSidebarDirectiveModule.name,
    ])
    .directive('overlay', OverlayDirectiveFactory())
    .directive('onErrorSrc', onSrcDirectiveInstance())
    .directive('promiseTracker', PromiseTrackerInstance())
    .factory('OutsideElementClick', OutsideElementClickInstance());
