import { Browser } from './services';
import { ConfigAPI } from './config-api';
import { IConfigExperiments, IConfigObj } from './types';
import { UserExperimentsStorage } from './storage-user-experiments';

export type ConfigPageExperiments = ReturnType<typeof normalize>;

export const SIDEBAR_BLACKLIST = ['allsaints_dev', 'allsaints-new', 'allsaints', 'sportsdirect', 'crs_'];
export const isSidebarBlackListOrg = (orgId: string) => SIDEBAR_BLACKLIST.some(id => orgId.includes(id));

export const normalize = (
    userLocalStorage: { experiments?: Partial<IConfigExperiments> },
    orgConfig: { organization: { id: string }; experiments?: Partial<IConfigExperiments> },
    userInternalConfig?: { experiments?: Partial<IConfigExperiments> },
) => {
    const localStorageExperiments = userLocalStorage.experiments ?? {};
    const orgExperiments = orgConfig.experiments ?? {};
    const userExperiments = userInternalConfig?.experiments ?? {};

    const get = (key: keyof IConfigExperiments, defaultValue: boolean | null = null): boolean | null => {
        return localStorageExperiments[key] ?? userExperiments[key] ?? orgExperiments[key] ?? defaultValue;
    };

    const sidebar = (() => {
        const org = orgConfig.organization.id;
        return isSidebarBlackListOrg(org) ? get('enableSidebar') : get('enableSidebarV2', true);
    })();
    return {
        multiplePropertyGroupBy: get('enableMultiplePropertyGroupBy'),
        segmentsInMetricsPage: Browser.isLocalhost() && get('enableSegmentsInMetricsPage'),
        metricsCategorization: get('enableMetricsCategorization'),
        sidebar: sidebar,
        // TODO: Remove these two flags once last version of sliding sidebar goes to "next-release" stage
        metricsInSidebar: sidebar,
        groupByPropertiesInSidebar: sidebar,
    };
};

export async function fetch(overrides?: { organization?: IConfigObj; user?: IConfigObj }) {
    return ConfigAPI.get().then(api => {
        return Promise.all([
            Promise.resolve(UserExperimentsStorage.get()).then(e => ({ experiments: e ?? {} })),
            overrides?.organization ?? api.organization.get(),
            overrides?.user ?? api.user.getInternal(),
        ]).then(([userLocalStorage, orgConfig, userConfig]) => {
            return normalize(userLocalStorage, orgConfig, userConfig);
        });
    });
}
