import _ from 'lodash';
import { KeyboardObserverAngular } from '../../../lib/dom/keyboard-observer';
import './selection-pebble.scss';

interface ISelectionPebbleFullModel {
    id: string;
    icon?: string;
    interactive?: boolean;
    selected?: boolean;
    onDoubleClick?: ($event: Event) => void;
    onClick?: ($event?: Event) => void;
    onCheckboxClick?: () => void;
    onIconClick?: ($event: Event) => void;
    color: 'red' | 'blue' | 'neutral';
    draggable?: boolean;
    selectable?: boolean;
    chevron?: boolean;
    checkbox?: boolean;
    disableClick?: boolean;
    shiftSelect?: boolean;
    label: string;
    value?: string | number;
}

interface SelectionPebbleDirectiveScope extends angular.IScope {
    model: ISelectionPebbleFullModel;
    disabled: boolean | undefined;
    pebbleClick: ($event: Event) => void;
    onCheckboxPebbleClick: () => void;
}

export const SelectionPebbleDirective = () => [
    (): angular.IDirective<SelectionPebbleDirectiveScope> => ({
        restrict: 'E',
        scope: {
            options: '=',
            model: '=',
            disabled: '=',
        },
        replace: true,
        template: `
        <div
            class="selection-pebble pebble-color-{{ model.color || 'neutral' }}"
            ng-class="{
                'interactive': ((model.onIconClick || model.onClick || model.onDoubleClick) && !disabled),
                'draggable': model.draggable,
                'selectable': model.selectable,
                'multi-select': options.multiSelect,
                'selected': model.selected,
                'neutral': model.color === 'neutral',
                'translucid': model.translucid,
                'checkbox': model.checkbox
            }"
            ng-click="pebbleClick($event)"
            ng-double-click="model.onDoubleClick($event)">
            <div class="pebble-checkbox" ng-click="onCheckboxPebbleClick($event)" ng-if="model.checkbox">
                <input type="checkbox"
                    ng-model="model.selected"
                    ng-disabled="model.disableClick"
                >
            </div>
            <div class="pebble-description">
                <div class="pebble-content">
                    <span ng-if="model.label !== undefined" class="pebble-label">{{ model.label }}</span>
                    <span ng-if="model.value !== undefined" class="pebble-value">{{ model.value }}</span>
                </div>
                <div class="pebble-icon" ng-if="model.number">
                    {{ model.number }}
                </div>
                <div class="selection-pebble-chevron" ng-if="model.chevron">
                    <i class="move-icon move-icon-right icon-right-open-mini"></i>
                </div>
                <i class="pebble-icon {{ model.icon }}"
                    ng-if="model.icon"
                    ng-click="model.onIconClick($event)">
                </i>
            </div>
        </div>
        `,
        link: (scope: SelectionPebbleDirectiveScope, element) => {
            let shiftActive = false;
            let checkboxClick = false;

            scope.pebbleClick = () => {
                if (checkboxClick || shiftActive) {
                    checkboxClick = false;
                    scope.model.onCheckboxClick?.();
                    return;
                }
                scope.model.onClick?.();
            };

            scope.onCheckboxPebbleClick = () => {
                checkboxClick = true;
            };

            // To remove in the future
            if ('id' in scope.model && scope.model.shiftSelect) {
                const keyboardObserver = new KeyboardObserverAngular(scope, window);
                const debouncedCB = _.debounce((event: KeyboardEvent) => {
                    if (!event.shiftKey) {
                        element.hasClass('shift-select') && element.removeClass('shift-select');
                        shiftActive = false;
                        return;
                    }
                    shiftActive = true;
                    !element.hasClass('shift-select') && element.addClass('shift-select');
                }, 100);
                keyboardObserver.onKeyPress(debouncedCB);
            }
        },
    }),
];

const SelectionPebbleModule = angular
    .module('42.components.selection-pebble', [])
    .directive('selectionPebble', SelectionPebbleDirective());

export default SelectionPebbleModule;
