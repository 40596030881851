import Utils from './utils';
import { CustomError } from './utils';
import * as Analytics from './analytics';
import { ConfigExperimentsSchema, IConfigExperiments } from './types';

export class UserExperimentsStorageAPI {
    protected key: string;
    protected storage: Storage | undefined;
    protected experiments: IConfigExperiments | null = null;

    constructor({ key, storage }: { key?: string; storage?: Storage } = {}) {
        this.key = key ?? '42-experiments';
        this.storage = storage ?? globalThis.localStorage;
    }

    get(): IConfigExperiments | null {
        if (this.experiments) {
            return Utils.copy(this.experiments);
        }
        if (!this.storage) {
            console.error('[UserExperimentsStorageAPI] No localStorage available, cannot load experiments flags.');
            return null;
        }
        try {
            const data = this.storage.getItem(this.key) ?? null;
            console.log('[UserExperimentsStorageAPI] Loaded experiments from localStorage:', data);
            if (!data) return null;
            this.experiments = ConfigExperimentsSchema.parse(JSON.parse(data));
            return Utils.copy(this.experiments);
        } catch (cause) {
            const error = new UserExperimentsStorageError('read error', { cause });
            Analytics.logError(error);
            return null;
        }
    }

    async set(experiments: IConfigExperiments) {
        try {
            ConfigExperimentsSchema.parse(experiments);
        } catch (cause) {
            const error = new UserExperimentsStorageError('write error', { cause });
            Analytics.logError(error);
            return;
        }
        this.experiments = Utils.copy(experiments);
        if (!this.storage) {
            console.error('[UserExperimentsStorageAPI] No storage available, cannot save experiments flags.');
            return;
        }
        try {
            console.log('[UserExperimentsStorageAPI] Setting experiments to:', experiments);
            this.storage.setItem(this.key, JSON.stringify(experiments));
        } catch (error) {
            if (isQuotaExceededError(error)) console.error('LocalStorage length:', localStorage?.length);
            Analytics.logError(error);
        }
    }
}

class UserExperimentsStorageError extends CustomError {}

function isQuotaExceededError(error: unknown): error is DOMException {
    return error instanceof DOMException && error.name === 'QuotaExceededError';
}

export const UserExperimentsStorage = new UserExperimentsStorageAPI();
UserExperimentsStorage.get();
